// i18next-extract-mark-ns-start wix-payment-gateway
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_monei from 'images/wix_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="wix-payment-gateway"
        title={t('Wix Payment Gateway')}
        description={t(
          'Grow your Wix store with MONEI’s payment gateway: Accept all major payment methods. Quick and easy integration. Dynamic pricing. Sign up now »'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>
                  Grow your e-commerce business faster with the advanced Wix payment gateway
                </Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You chose Wix as your e-commerce platform because it’s affordable, easy to use, and
                lets you customize it to your brand. Why not use a payment gateway that offers the
                same benefits to help you grow your Wix store?
              </Trans>
              <Trans parent="p">
                Meet MONEI, the advanced e-commerce payment gateway that enables you to accept and
                manage all major and alternative payment methods in a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_monei} alt="MONEI payment gateway" title="MONEI payment gateway" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept more payment methods and make more sales</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> helps
                you reach more people, boost customer satisfaction, and sell more. We aggregate all
                payment methods into a single platform to help you offer your customers their
                preferred payment options and increase conversion rates.
              </Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/credit-cards">
                    Credit cards
                  </InternalPageLink>{' '}
                  -{' '}
                  <InternalPageLink slug="visa-mastercard-for-wix">
                    Visa, Mastercard
                  </InternalPageLink>
                  , and more
                </Trans>
                <Trans parent="li">
                  Digital wallets -{' '}
                  <InternalPageLink slug="apple-pay-for-wix">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="google-pay-for-wix">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, or{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods such as <Link to="/bizum-for-wix/">Bizum</Link>(in Spain)
                </Trans>
                <Trans parent="li">
                  Direct Debit -{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">SEPA</InternalPageLink>{' '}
                  and{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="features/recurring-payments">
                    Subscription payments
                  </InternalPageLink>
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Build trust by customizing your Wix checkout page</Trans>
              </SectionHeader>
              <Trans parent="p">
                You can{' '}
                <AnchorLink
                  href="https://support.wix.com/en/article/wix-stores-customizing-the-checkout-page"
                  target="_blank">
                  customize your checkout page
                </AnchorLink>{' '}
                within your Wix account, thanks to our native integration with the platform. All you
                have to do is select MONEI as your <Link to="/">payment gateway</Link> in the
                settings tab and you’ll be ready to start selling.
              </Trans>
              <Trans parent="p">
                Prefer to have us host your checkout page? You can use MONEI’s{' '}
                <AnchorLink
                  href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page"
                  target="_blank">
                  Hosted Payment Page
                </AnchorLink>{' '}
                to build a first-class payment experience. Enjoy a fully responsive and mobile-ready
                checkout page, and{' '}
                <AnchorLink
                  href="https://support.monei.com/hc/en-us/categories/360003383258-Customization"
                  target="_blank">
                  customize
                </AnchorLink>{' '}
                it with your logo and brand colors. Build trust by using your store’s domain so
                shoppers stay on your website during the entire payment flow.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Spend less on fees as your business grows</Trans>
              </SectionHeader>
              <Trans parent="p">
                Our dynamic billing model means as you sell more your transaction fees decrease.
                Choose a flexible payment gateway that grows with your business.
              </Trans>
              <Trans parent="p">
                <Link to="/pricing/">View Pricing</Link>
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Effortlessly integrate with your Wix store</Trans>
            </SectionHeader>
            <Trans parent="p" style={{maxWidth: 800, margin: 'auto'}}>
              Use MONEI’s Wix integration to seamlessly integrate with your online store and start
              accepting more payment methods today. Once you’ve created your{' '}
              <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                MONEI account
              </AnchorLink>
              , all you have to do is go to your Wix control panel and find the{' '}
              <AnchorLink
                href="https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wicm91dGVcIjpcInRlc3RBcHBcIixcImFwcERlZklkXCI6XCJiZWQyMTg3OS0xMmUyLTQyNjYtYWQyYy01MGM5ZmQ1ODkyMTZcIixcInZlcnNpb25cIjpcIjEuMC4xMFwifSIsImlhdCI6MTYyODc1OTI4NX0.QympFw45ADi-5cGH2ibQP8PYa8XYaIcwR1CJ9uiUhNs"
                target="_blank">
                MONEI app in Wix's app market
              </AnchorLink>
              , then add it and authorize the connection. In a few short steps, you’ll be ready to
              accept payments and manage refunds, all from your Wix dashboard.
            </Trans>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "wix-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
